import React from 'react';
import DownloadIcon from '../../../assets/svg/download.svg';

import styles from './styles/download-button.style.component.module.css';

interface IDownloadButton {
	text: string;
	onClick: () => void;
	customStyles?: React.CSSProperties;
}

export const DownloadButton = ({ text, onClick, customStyles }: IDownloadButton) => {
	return (
		<div className={styles.downloadButton} onClick={onClick} style={customStyles}>
			<img src={DownloadIcon} alt="icono de descarga" />
			<span>{text}</span>
		</div>
	);
};

export default DownloadButton;
