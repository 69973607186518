import React from 'react';
import { useDispatch } from 'react-redux';
import { INTERNAL_MESSAGES } from '../config/response-codes';
import { IResponseEfirmaSigning } from '../interfaces/efirma.interface';
import { setLoading } from '../store/modules/loading';
import { setLoadingRequest } from '../store/modules/loadingRequest';

interface IDigitalSigningFunction {
	setDigitalSigningResponse: React.Dispatch<
		React.SetStateAction<IResponseEfirmaSigning>
	>;
	setModalMessage: React.Dispatch<React.SetStateAction<string>>;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useDigitalSigning({
	setDigitalSigningResponse,
	setModalMessage,
	setShowModal,
}: IDigitalSigningFunction) {
	const dispatch = useDispatch();

	const digitalSigningFunction = (error: string, response: IResponseEfirmaSigning) => {
		if (error === null) {
			setDigitalSigningResponse(response);
		} else {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			dispatch(setLoading({ loading: false }));
			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE); //TO-DO: Verificar mensaje de error cuando falla el firmado
			setShowModal(true);
		}
	};

	return {
		digitalSigningFunction,
	};
}
