export const initialValues = {
	consignaAnterior: '',
	consignaNueva: '',
	confirmP: '',
};

export const dataError = {
	valid: false,
	msg: '',
};
