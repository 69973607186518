import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IConfirmation } from '..';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest } from '../../../../../hooks/useApiRequest';
import { generatePDF } from '../../../../../utils/generatePDF';
import { useDigitalSigning } from '../../../../../hooks/digitalSigning';
import {
	defaultEfirmaSigningResponse,
	IResponseEfirmaSigning,
} from '../../../../../interfaces/efirma.interface';
import { INTERNAL_MESSAGES } from '../../../../../config/response-codes';
import { setLoadingRequest } from '../../../../../store/modules/loadingRequest';

declare global {
	interface Window {
		firmarCadenaOriginal: any;
	}
}

export function useConfirmation({
	setActualPage,
	pdf,
	setPdf,
	folio,
	setFolio,
	cadenaOriginal,
	setCadenaOriginal,
	nrp,
	setNrp,
	eFirmaData,
	setEFirmaData,
}: IConfirmation) {
	//Necesario para el firmado
	const firmarCadenaOriginal = window.firmarCadenaOriginal;
	const [digitalSigningResponse, setDigitalSigningResponse] =
		useState<IResponseEfirmaSigning>(defaultEfirmaSigningResponse);

	const dispatch = useDispatch();
	const fileSrc = 'data:application/pdf;base64,' + pdf;
	const [isAccepted, setIsAccepted] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string>('');
	const [modalType, setModalType] = useState<string>('error');

	const { digitalSigningFunction } = useDigitalSigning({
		setDigitalSigningResponse,
		setModalMessage,
		setShowModal,
	});

	const { execute, status } = useApiRequest<unknown, unknown>({
		path: 'baja-representante-legal/solicitar-baja',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			setIsAccepted(true);
		} else if (status === 'error') {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setShowModal(true);
		}
	}, [status]); // eslint-disable-line react-hooks/exhaustive-deps

	//Validar que la respuesta del firmado del documento es exitosa
	useEffect(() => {
		if (
			digitalSigningResponse.code === '0' &&
			digitalSigningResponse.message === 'OK'
		) {
			execute({
				nrp: nrp,
				folio: folio,
				acuse: digitalSigningResponse.pdfSignedB64,
			});
		}
	}, [digitalSigningResponse]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleGenerateReceipt = () => {
		dispatch(setLoadingRequest({ loadingRequest: true }));
		signDocument();
	};

	//Funcion para firmar el documento
	const signDocument = () => {
		dispatch(setLoadingRequest({ loadingRequest: true }));
		try {
			firmarCadenaOriginal(
				{
					...eFirmaData,
					password: eFirmaData.psw,
					serverIP: URLS.EFIRMA.VALIDATE,
					pdfB64: pdf,
					pdfContentType: 'application/pdf',
					cadenaOriginalValue: cadenaOriginal,
				},
				digitalSigningFunction
			);
		} catch (error) {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setShowModal(true);
		}
	};

	const handleDownloadRecipt = (): void => {
		generatePDF(pdf, 'pdf', `Recibo_de_baja_${nrp}`);
	};

	const handleDownloadPDF = () => {
		generatePDF(digitalSigningResponse.pdfSignedB64, 'pdf', `Baja_${nrp}`);
		setModalType('successful');
		setShowModal(true);
		setModalMessage('Descargaste el acuse exitosamente.');
	};

	const handleCancelRemoval = () => {
		setEFirmaData({
			keyBinary: '',
			cerBinary: '',
			cerB64: '',
			psw: '',
		});
		setPdf('');
		setFolio('');
		setNrp('');
		setCadenaOriginal('');
		setActualPage('form');
	};

	return {
		fileSrc,
		isAccepted,
		showModal,
		modalMessage,
		modalType,
		handleGenerateReceipt,
		handleDownloadPDF,
		handleCancelRemoval,
		handleDownloadRecipt,
	};
}
