import { useEffect } from 'react';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useDispatch } from 'react-redux';

import {
	IActiveAccountParams,
	IActiveAccountResponse,
} from '../interfaces/auth.interface';
import { setLoading } from '../../../store/modules/loading';
import { RESPONSE_CODES } from '../../../config/response-codes';
export const useActivaCuenta = () => {
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(window.location.search);
	const mail = queryParams.get('email')?.toString() as string;
	const data: IActiveAccountParams = {
		correo: mail,
	};
	const {
		execute,
		value: valueLogin,
		status,
	} = useApiRequest<IActiveAccountParams, IActiveAccountResponse>({
		path: 'autentica/activateUser',
	});
	const handleActiveAccount = () => {
		dispatch(setLoading({ loading: true }));
		execute({ ...data });
	};

	useEffect(() => {
		if (status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
			dispatch(setLoading({ loading: false }));
		} else if (
			(status === 'success' || status === 'error') &&
			valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			dispatch(setLoading({ loading: false }));
		}
	}, [valueLogin?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps
	return {
		handleActiveAccount,
		status,
		valueLogin,
	};
};
