// Resources
import styles from './custom-alert-cc.styles.component.module.css';

interface ICustomAlert {
	message?: string;
}

const CustomAlertCC = (props: ICustomAlert) => {
	const { message } = props;
	return (
		<div className={styles.alert}>
			<span>{message}</span>
		</div>
	);
};

export default CustomAlertCC;
