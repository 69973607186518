import { Header, Navigation, Hero, Footer, Breadcrumb, Guides } from './';
import { Outlet, useLocation } from 'react-router-dom';
import style from './styles/layout-styles.component.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ModalError } from './modal-error/modal-error.component';
//import NavigationMobile from './navigation-mobile.component';

export default function Layout() {
	const { about, guide, title, background, breadcrumbs } = useSelector(
		(state: RootState) => state.layout
	);
	const { pathname } = useLocation();
	//const [showMenu, setShowMenu] = useState(false);

	return (
		<>
			<div className={style.header}>
				<Header />
				<Navigation />
			</div>
			{/*<NavigationMobile showMenu={showMenu} setShoMenu={setShowMenu} />*/}
			{pathname !== '/inicio' ? (
				<>
					{title !== '' && (
						<Hero title={title} backgroundImageUrl={background} />
					)}
					{breadcrumbs?.length > 0 && <Breadcrumb options={breadcrumbs} />}

					<div className={style.content}>
						<Guides labelInformation={about} labelGuide={guide} />
						<div className={style.container}>
							<Outlet />
						</div>
					</div>
				</>
			) : (
				<Outlet />
			)}
			<ModalError />
			<Footer />
		</>
	);
}
