import { Link } from 'react-router-dom';
import style from '../styles/home-styles.component.module.css';

interface Props {
	display: number;
}
const SecondChildren = ({ display }: Props) => {
	const servicios = [
		{
			text: 'Conoce los videotutoriales de los servicios disponibles.',
			id: 1,
			url: process.env.REACT_APP_URL_HOME_VIDEO_GUIDE,
		},
		{
			text: 'Consulta nuestras guías e instructivos para que realices de manera rápida y cómoda tus servicios y trámites. ',
			id: 2,
			url: process.env.REACT_APP_URL_HOME_USE_GUIDE,
		},
		{
			text: 'Envíanos tus dudas o consultas a través del Centro de Atención Empresarial.',
			id: 3,
			url: '/centro-de-atencion/solicitud',
		},
	];

	return (
		<div
			className={`${style.secondChildrenContainerTwo}  ${
				display === 0 && style.displaySecondChildren
			}`}
		>
			<b className={style.question}>¿Eres nuevo? Empieza aquí</b>
			<div className={style.secondChildren}>
				{servicios.map((servicio) => {
					return (
						<div className={style.boxs} key={servicio.id}>
							<div className={style.boxs1}>
								{servicio.id === 3 ? (
									<Link to={servicio.url!} className={style.text}>
										{servicio.text}
									</Link>
								) : (
									<a
										href={servicio.url}
										className={style.text}
										target="_blank"
										rel="noreferrer"
									>
										{servicio.text}
									</a>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SecondChildren;
