// Dependencies
import React from 'react';
import { AlertColor, Alert } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

// Resources
import styles from './styles/alert-styles.component.module.css';
import { ALERT_COLORS, ALERT_SEVERITY_TYPES } from '../constants/alert.constants';
import { Close, InfoOutlined, WarningAmberRounded } from '@mui/icons-material';

interface ICustomAlert {
	id?: string;
	show: boolean;
	severity?: AlertColor;
	message?: string | React.ReactChild;
	onClose?: () => void;
	children?: JSX.Element;
	hasMargin?: boolean;
	hasIcon?: boolean;
	center?: boolean;
}

const CustomAlert = (props: ICustomAlert) => {
	const {
		id,
		show,
		severity = 'error',
		message,
		onClose,
		children,
		hasMargin,
		hasIcon,
		center,
	} = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (severity) {
			case ALERT_SEVERITY_TYPES.WARNING:
				color = ALERT_COLORS.WARNING_BACKGROUND;
				break;
			case ALERT_SEVERITY_TYPES.INFO:
				color = ALERT_COLORS.INFO_BACKGROUND;
				break;
			case ALERT_SEVERITY_TYPES.ERROR:
				color = ALERT_COLORS.ERROR_BACKGROUND;
				break;
			case ALERT_SEVERITY_TYPES.SUCCESS:
				color = ALERT_COLORS.SUCCESS_BACKGROUND;
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (severity) {
			case ALERT_SEVERITY_TYPES.WARNING:
				color = ALERT_COLORS.WARNING_TEXT;
				break;
			case ALERT_SEVERITY_TYPES.INFO:
				color = ALERT_COLORS.INFO_TEXT;
				break;
			case ALERT_SEVERITY_TYPES.ERROR:
				color = ALERT_COLORS.ERROR_TEXT;
				break;
			case ALERT_SEVERITY_TYPES.SUCCESS:
				color = ALERT_COLORS.SUCCESS_TEXT;
				break;
			default:
				break;
		}
		return color;
	};
	return (
		<Alert
			className={
				center ? `${styles.alert} ${styles.centerAlert}` : `${styles.alert}`
			}
			sx={
				!hasMargin
					? {
							display: show ? 'flex' : 'none',
							padding: '0px 12px 0px 12px',
							backgroundColor: getColorBackground(),
							color: getColorLabel(),
							width: '100%',
							margin: 0,
							fontSize: '16px',
					  }
					: {
							display: show ? 'flex' : 'none',
							padding: '0px 12px 0px 12px',
							backgroundColor: getColorBackground(),
							color: getColorLabel(),
							width: '100%',
							marginTop: '8px',
							fontSize: '16px',
					  }
			}
			onClose={severity === 'warning' ? onClose : undefined}
			severity={severity}
			icon={hasIcon}
			iconMapping={{
				error: <Close style={{ color: '#DD052B', width: '16px' }} />,
				success: <DoneIcon style={{ color: '#3ABE21' }} />,
				warning: <WarningAmberRounded style={{ color: '#E35700' }} />,
				info: <InfoOutlined style={{ color: '#293990' }} />,
			}}
			data-testid={`alert-input-${id}`}
		>
			{children !== undefined && children}
			{children === undefined && message ? message : ''}
		</Alert>
	);
};

export default CustomAlert;
