import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

const theme = createTheme(
	{
		palette: {
			primary: {
				main: '#293990',
				dark: '#212E74',
			},
			secondary: {
				main: '#000000',
				light: '#293990',
			},
			success: {
				main: '#0abb87',
				dark: '#079531',
				light: '#acf7c2',
				contrastText: '#ffffff',
			},
			warning: {
				main: '#f1d190',
				dark: '#976807',
				light: '#FFF4DE',
				contrastText: '#FFA800',
			},
			error: {
				main: '#DD0528',
			},
			info: {
				main: '#293990',
				light: '#a7b2eb',
				dark: '#283990',
				contrastText: '#ffffff',
			},
			background: {
				default: '#f8f8f8',
			},
			grey: {
				'200': '#CBCBCB',
			},
		},
	},
	esES
);

export const themBackdrop = createTheme({
	components: {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: 99999,
					backgroundColor: 'rgba(0,0,0,0.6)',
				},
			},
		},
	},
});

export default theme;
