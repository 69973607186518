// import styled from 'styled-components';
import { styled } from '@mui/material/styles';

export const ButtonSolid = styled('button')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#FFFFFF',
	borderRadius: 24,
	border: 'unset',
	minHeight: 48,
	height: 'auto',
	width: '100%',
	fontWeight: '500',
	fontSize: 18,
	fontFamily: 'Geomanist',
	lineHeight: '18px',
	backgroundColor: '#293990',
	cursor: 'pointer',
	outline: 'none',
	WebkitTapHighlightColor: 'rgba(0,0,0,0)',
	'&:hover': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-dark-blue'
		),
		transition: '0.5s',
	},
	'&:active': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-light-blue'
		),
		transition: '0.5s',
	},
	'&:disabled': {
		backgroundColor: '#C7C6C5',
		cursor: 'default',
	},
	'&:focus': {
		outlineColor: 'transparent',
		outlineStyle: 'none',
	},
}));

export const ButtonOutlined = styled('button')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: getComputedStyle(document.documentElement).getPropertyValue(
		'--color-primary-blue'
	),
	borderRadius: 50,
	borderWidth: 2,
	borderStyle: 'solid',
	borderColor: getComputedStyle(document.documentElement).getPropertyValue(
		'--color-primary-blue'
	),
	minHeight: 47,
	height: 'auto',
	width: '100%',
	fontWeight: '500',
	fontSize: 18,
	fontFamily: 'Geomanist',
	lineHeight: '18px',
	backgroundColor: '#FFFFFF',
	cursor: 'pointer',
	outline: 'none',
	WebkitTapHighlightColor: 'rgba(0,0,0,0)',
	'&:hover': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-lighter-blue'
		),
		transition: '0.5s',
	},
	'&:focus': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-light-blue'
		),
		color: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-9-grey'
		),
		outlineColor: 'transparent',
		outlineStyle: 'none',
	},
	'&:active': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-light-blue'
		),
		color: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-9-grey'
		),
	},
	'&:disabled': {
		backgroundColor: '#C7C6C5',
		cursor: 'default',
		color: '#FFFFFF',
		borderWidth: 0,
	},
}));

export const ButtonSolidRed = styled('button')(({ theme }) => ({
	fontFamily: 'Geomanist',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#FFFFFF',
	borderRadius: 24,
	minHeight: 48,
	height: 'auto',
	width: '100%',
	fontWeight: '500',
	fontSize: 18,
	backgroundColor: '#DD052B',
	border: 'unset',
	cursor: 'pointer',
	outline: 'none',
	WebkitTapHighlightColor: 'rgba(0,0,0,0)',
	'&:hover': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-dark-red'
		),
		transition: '0.5s',
	},
	'&:active': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-primary-red'
		),
		transition: '0.5s',
	},
	'&:focus': {
		backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
			'--color-light-red'
		),
		cursor: 'default',
		outlineColor: 'transparent',
		outlineStyle: 'none',
	},
	'&:disabled': {
		backgroundColor: '#C6C6C6',
		cursor: 'default',
	},
}));
