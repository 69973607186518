import { lazy, LazyExoticComponent } from 'react';

const Linking = lazy(() => import('../pages/vinculacion'));
const EmployeeInquiryPage = lazy(() => import('../pages/employee-inquiry'));
const DictaminatorsInquiryPage = lazy(() => import('../pages/dictaminators-inquiry'));
const ExecutorsAndWorkersPage = lazy(() => import('../pages/notifiers-workers'));
const ConsultaIncidencias = lazy(() => import('../pages/incidents-inquiry'));
const AffiliationMovements = lazy(() => import('../pages/affiliation-movements'));
const CompaniesOf10 = lazy(() => import('../pages/companies-10'));

type JSXComponent = () => JSX.Element;

interface Route {
	path: string;
	to: string;
	private?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
	idSistema: string;
}

export const queries: Route[] = [
	{
		to: '/consultas/empresas-de-diez',
		path: '/consultas/empresas-de-diez',
		name: 'Empresas de Diez',
		Component: CompaniesOf10,
		private: true,
		idSistema: '24',
	},
	{
		to: '/consultas/consulta-dictaminadores',
		path: '/consultas/consulta-dictaminadores',
		name: 'Dictaminadores autorizados',
		Component: DictaminatorsInquiryPage,
		private: true,
		idSistema: '26',
	},
	{
		to: '/consultas/notificadores-ejecutores',
		path: '/consultas/notificadores-ejecutores',
		name: 'Notificadores y ejecutores',
		Component: ExecutorsAndWorkersPage,
		private: true,
		idSistema: '27',
	},

	{
		to: '/consultas/validacion-de-documentos',
		path: '/consultas/validacion-de-documentos',
		name: 'Validación de documentos',
		Component: Linking,
		private: true,
		idSistema: '29',
	},
	{
		to: '/consultas/trabajadores-acreditados',
		path: '/consultas/trabajadores-acreditados',
		name: 'Trabajadores acreditados',
		Component: EmployeeInquiryPage,
		private: true,
		idSistema: '6',
	},
	{
		to: '/consultas/incidencias',
		path: '/consultas/incidencias',
		name: 'Incidencias patronales',
		Component: ConsultaIncidencias,
		private: true,
		idSistema: '7',
	},
	{
		to: '/consultas/movimientos-afiliatorios',
		path: '/consultas/movimientos-afiliatorios',
		name: 'Movimientos afiliatorios',
		Component: AffiliationMovements,
		private: true,
		idSistema: '8',
	},
];
