import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiRequest } from '../../hooks/useApiRequest';

import { IRefreshToken } from './interfaces/token.interface';
import { INTERNAL_CODES } from '../../config/response-codes';
import { getProfile } from '../../utils/getProfile';

interface Props {
	setTimeExpires: any;
}
export const useRefreshToken = ({ setTimeExpires }: Props) => {
	const [openError, setOpenError] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string>('');

	const {
		execute: executeRefreshToken,
		value: valueRefreshToken,
		status: statusRefreshToken,
	} = useApiRequest<'', IRefreshToken>({
		path: 'autentica/token-refresh',
		baseURL: process.env.REACT_APP_API_URL,
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (valueRefreshToken) {
			if (
				valueRefreshToken?.internalCode === INTERNAL_CODES.SUCCESSFUL &&
				statusRefreshToken === 'success'
			) {
				sessionStorage.setItem(
					'userData',
					JSON.stringify(valueRefreshToken?.data)
				);
				const profile = getProfile();
				const timeToExpire = 1000 * (profile?.token_duration || 300);
				setTimeExpires(timeToExpire);
			} else if (
				valueRefreshToken?.internalCode ===
				INTERNAL_CODES.TOKEN_VALIDATION_ERROR_CODE
			) {
				sessionStorage.removeItem('userData');
				sessionStorage.removeItem('key');
				sessionStorage.removeItem('servicios');

				navigate('/');
			} else if (
				valueRefreshToken?.internalCode !== INTERNAL_CODES.SUCCESSFUL &&
				(statusRefreshToken === 'success' || statusRefreshToken === 'error')
			) {
				setOpenError(true);
				setModalMessage(valueRefreshToken?.message!);
			}
		}
	}, [valueRefreshToken?.code, statusRefreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		openError,
		setOpenError,
		modalMessage,
		executeRefreshToken,
	};
};
