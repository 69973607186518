import { lazy, LazyExoticComponent } from 'react';

const YourRequestPage = lazy(() => import('../pages/your-request'));
const MakeAppointment = lazy(() => import('../pages/make-appointment'));

type JSXComponent = () => JSX.Element;

interface Route {
	path: string;
	to: string;
	private?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
	idSistema: string;
}

export const attentionCenter: Route[] = [
	{
		to: '/centro-de-atencion/solicitud',
		path: '/centro-de-atencion/solicitud',
		name: 'Realiza y da Seguimiento a tu solicitud',
		Component: YourRequestPage,
		private: true,
		idSistema: '31',
	},

	{
		to: '/centro-de-atencion/agenda-tu-cita',
		path: '/centro-de-atencion/agenda-tu-cita',
		name: 'Agenda tu cita',
		Component: MakeAppointment,
		private: true,
		idSistema: '33',
	},
];
