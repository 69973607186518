// Components
import React from 'react';
import { Modal, Fade, Paper, Box } from '@mui/material';
import IconCustom from './icon-custom.component';

//Styles
import styles from './styles/modal.component.module.css';

//Resources
import closeIcon from '../assets/svg/close.svg';
import successIcon from '../assets/svg/sucessModal.svg';
import failIcon from '../assets/svg/failModal.svg';
import CustomButton from './button.component';

export interface IModal {
	openModal: boolean;
	children: string | React.ReactElement;
	labelButton?: string;
	title?: string;
	type?: string | 'normal' | 'successful' | 'error';
	style?: React.CSSProperties;
	closeButton?: boolean;
	setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
	callback?: () => void;
	closeIconCallback?: () => void;
}

const CustomModal = (props: IModal) => {
	const {
		openModal,
		setOpenModal,
		children,
		title,
		type,
		labelButton,
		style,
		closeButton,
		callback,
		closeIconCallback,
	} = props;

	const handleClose = () => {
		if (setOpenModal) {
			setOpenModal(!openModal);
		}
		if (callback) {
			callback();
		}
	};
	return (
		<Modal
			open={openModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Fade in={openModal}>
				<Box>
					<Paper className={styles.mainContainer}>
						{(type || title) && (
							<div
								className={
									type === 'normal'
										? styles.headContainer
										: styles.headContainerAlert
								}
							>
								{title && (
									<h4 style={{ margin: 0, color: '#333333' }}>
										{title}
									</h4>
								)}
								{type === 'normal' && (
									<span onClick={closeIconCallback}>
										<IconCustom
											styles={{ cursor: 'pointer' }}
											svg={closeIcon}
											onClick={handleClose}
										/>
									</span>
								)}
								{type === 'successful' && (
									<span>
										<IconCustom
											styles={{ width: '57' }}
											svg={successIcon}
										/>
									</span>
								)}
								{type === 'error' && (
									<span>
										<IconCustom
											styles={{ width: '57' }}
											svg={failIcon}
										/>
									</span>
								)}
							</div>
						)}
						<div style={{ ...style }} className={styles.body}>
							{children}
							{closeButton && (
								<div className={styles.containerButtonModal}>
									<CustomButton
										onClick={handleClose}
										styles={{ width: 211 }}
										variant="solid"
										label={labelButton ? labelButton : 'Cerrar'}
									/>
								</div>
							)}
						</div>
					</Paper>
				</Box>
			</Fade>
		</Modal>
	);
};

export default CustomModal;
