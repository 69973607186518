import { createSlice } from '@reduxjs/toolkit';

export type ILayout = {
	title: string;
	breadcrumbs: [];
	background: string;
	guide?: string;
	about?: string;
};

export const defaultLayout: ILayout = {
	title: '',
	breadcrumbs: [],
	background: '',
};

export const layoutSlice = createSlice({
	name: 'title',
	initialState: defaultLayout,
	reducers: {
		setLayout: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { setLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
