import { useState, useEffect } from 'react';
import CustomButton from '../../../components/button.component';
import style from '../styles/home-styles.component.module.css';
import SecondChildren from './SecondChildren';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDiezPlusShow } from '../../../store/modules/diezPlus';
import empresas from '../../../assets/imgs/empresas.jpeg';
import fiscal from '../../../assets/imgs/fiscal.jpg';
import sia from '../../../assets/imgs/sia.jpg';
import tramite from '../../../assets/imgs/tramite.jpeg';
import Box from '@mui/material/Box';
import { getProfile } from '../../../utils/getProfile';

const SecondComponent = () => {
	const [listServices, setListServices] = useState<any[]>([]);
	const dispatch = useDispatch();

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
	};
	const servicesList = [
		{
			name: 'Comprobante fiscal ',
			name2: 'Registra tus datos fiscales y obtén tus CFDI de pagos patronales.',
			id: '15',
			url: '/mis-tramites/comprobante-fiscal',
			img: fiscal,
		},
		{
			name: 'Trámite de crédito ',
			name2: 'Realiza la confirmación electrónica de los trámites de crédito de tus trabajadores.',
			id: '23',
			url: '/mis-tramites/tramite-de-credito',
			img: tramite,
		},
		{
			name: ' SIA ',
			name2: 'Consulta de forma masiva los avisos de crédito de tus trabajadores desde tu sistema.',
			id: '19',
			url: '/mis-tramites/servicios-de-intercomunicacion',
			img: sia,
		},
		{
			name: ' Empresas de Diez Plus ',
			name2: 'Conoce si calificas como una Empresa de Diez Plus y los beneficios que tiene ser parte del programa.',
			id: '25',
			url: '/consultas/empresas-de-diez',
			img: empresas,
		},
	];

	useEffect(() => {
		const profile = getProfile();
		const servicePerfil = profile?.contacto.servicios;
		const isArray = servicePerfil instanceof Array;
		const servicePerfilArr = isArray ? servicePerfil : [servicePerfil];
		const services: any[] = [];
		servicesList.map((service: any) =>
			servicePerfilArr?.map((servicio) => {
				return servicio?.idSistema === service.id && services.push(service);
			})
		);
		setListServices(services);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={style.secondContainer}>
			{listServices.length > 0 && (
				<Slider {...settings} className={style.carousel}>
					{listServices.map((service: any) => {
						return (
							<Box
								key={service.id}
								className={style.divCarousel}
								sx={{
									background: `linear-gradient(rgba(41, 57, 144, 0.4),rgba(41, 57, 144, 0.4)), url(${service.img}),no-repeat`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}
							>
								<div className={style.divCarouselText}>
									<p className={style.fontCarouselTitle}>
										{service.name}
									</p>
									<p className={style.fontCarouselText}>
										{service.name2}
									</p>
									<div className={style.buttonCarousel}>
										<Link
											to={service.url}
											className={style.linkCarousel}
										>
											<CustomButton
												variant="solid"
												onClick={
													service.id === '25'
														? () =>
																dispatch(
																	setDiezPlusShow(true)
																)
														: () => ''
												}
											>
												<p className={style.textButton}>
													Más información
												</p>
											</CustomButton>
										</Link>
									</div>
								</div>
							</Box>
						);
					})}
				</Slider>
			)}
			<SecondChildren display={listServices.length} />
		</div>
	);
};

export default SecondComponent;
