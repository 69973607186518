// Dependencies
import { Backdrop } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

//
import gifLoading from '../assets/gifts/loading.gif';
import { themBackdrop } from '../config/theme';
import { RootState } from '../store';
import styles from './styles/loading-request-styles.component.module.css';

const ModalRequestLoading = () => {
	const { loadingRequest } = useSelector((state: RootState) => state.loadingRequest);

	return (
		<>
			{loadingRequest && (
				<ThemeProvider theme={themBackdrop}>
					<Backdrop open={loadingRequest}>
						<div className={styles.containerLoading}>
							<p className={styles.textLoading}>Espera un momento</p>
							<img
								src={gifLoading}
								alt="loading-infonavit-gift"
								className={styles.imgGift}
							/>
						</div>
					</Backdrop>
				</ThemeProvider>
			)}
		</>
	);
};

export default ModalRequestLoading;
