import style from './styles/breadcrumb.styles.component.module.css';

interface IBreadcrumb {
	options?: Array<string>;
}

export function Breadcrumb(props: IBreadcrumb) {
	const { options } = props;
	return (
		<div className={style.content}>
			<div className={style.breadcrumb}>
				<div className={style.breadcrumbItemContainer}>
					<div className={style.breadcrumbItem}>
						<a href="/">Patrones</a>
					</div>
					<span> &gt; </span>
				</div>
				{options?.map((label, index, arr) => (
					<div className={style.breadcrumbItemContainer} key={index}>
						<div className={style.breadcrumbItem}>
							<p>{label}</p>
						</div>
						{arr.length - 1 === index ? '' : <span> &gt; </span>}
					</div>
				))}
			</div>
		</div>
	);
}
