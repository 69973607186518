/* eslint-disable react-hooks/exhaustive-deps */
import styles from './style.module.css';
import { INTERNAL_CODES, RESPONSE_CODES } from '../../config/response-codes';
import { getProfile } from '../../utils/getProfile';
// import style from '../../components/styles/header-styles.component.module.css';
import logoLogin from '../../assets/svg/logo-login.svg';

// Components
import { useNavigate } from 'react-router-dom';
import { useLoginCRM } from './hooks/useLoginCRM';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Modal } from '@mui/material';
import CustomModal from '../../components/modal.component';
import Footer from '../../components/footer.component';
import CustomButton from '../../components/button.component';
import Information from '../../components/information.component';
import { setShowQuestion } from '../../store/modules/questions';
import { RootState } from '../../store';

const LoginCRM = () => {
	// Get all posts

	const { valueLogin, status, modal, openModal, setOpenModal } = useLoginCRM();
	const [show, setShow] = useState<boolean>(false);
	const dataQuestion = useSelector((state: RootState) => state.questions);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		if (getProfile()) {
			navigate('/inicio');
		}
	});
	const closeModal = () => {
		setShow(false);
	};
	return (
		<>
			<Grid className={styles.subContainer} container spacing={0}>
				<Grid
					className={styles.mainContainerImgs}
					item
					xs={12}
					sm={12}
					md={7}
					lg={8}
				>
					<div className={styles.containerImgs}>
						<div className={styles.subContainerImgs}>
							<img
								src={logoLogin}
								className={styles.imgLogoInfonavit}
								alt="logo-infonavit"
							/>
						</div>
						<p className={styles.textInformation}>
							El Portal Empresarial te permite consultar información y hacer
							trámites en línea relacionados con tus empleados.
						</p>
					</div>
				</Grid>
			</Grid>
			{(status === 'success' && valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL) ||
			(status === 'error' &&
				valueLogin?.internalCode !== INTERNAL_CODES.SUCCESSFUL) ? (
				<CustomModal
					openModal={openModal}
					setOpenModal={setOpenModal}
					title={modal.title}
					type={modal.type}
					labelButton={modal.labelButton}
					closeButton={false}
					style={{ fontSize: 18, fontWeight: 400 }}
				>
					<>
						{modal.body}
						<div className={styles.buttonsClose}>
							<div className={styles.buttonClose}>
								<CustomButton
									onClick={() => navigate('/')}
									label="Cerrar"
									variant="solid"
								/>
							</div>
						</div>
					</>
				</CustomModal>
			) : (
				''
			)}

			<Modal
				open={show}
				onClose={closeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ overflowY: 'scroll', width: '100%' }}
			>
				<div
					style={{
						background: '#ffff',
						width: '60%',
						borderRadius: '8px',
						padding: '40px 24px',
						margin: '60px auto',
					}}
				></div>
			</Modal>
			<CustomModal
				openModal={dataQuestion}
				setOpenModal={setShowQuestion}
				title="Cierre de sesión"
				type="normal"
				style={{ fontSize: 18, fontWeight: 400 }}
				closeIconCallback={() => dispatch(setShowQuestion(false))}
			>
				<>
					<p className={styles.text}>
						Con el fin de mejorar nuestros servicios, te agradecemos que
						contestes las siguientes preguntas sobre el Portal Empresarial
					</p>
					<div className={styles.buttons}>
						<div className={styles.button2}>
							<CustomButton
								// onClick={() => dispatch(setShowQuestion(false))}
								variant="outlined"
								label="Salir"
							/>
						</div>
						<div className={styles.button1}>
							<a
								href={process.env.REACT_APP_URL_ENCUESTA}
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none' }}
							>
								<CustomButton
									// onClick={() => dispatch(setShowQuestion(false))}
									variant="solid"
									label="Aceptar"
								/>
							</a>
						</div>
					</div>
				</>
			</CustomModal>
			<Information />
			<Footer />
		</>
	);
};
export default LoginCRM;
