import { createSlice } from '@reduxjs/toolkit';
import { defaultValueUser } from '../../models/user.model';

export const userSlice = createSlice({
	name: 'user',
	initialState: defaultValueUser,
	reducers: {
		createUser: (state, action) => action.payload,
		editUser: (state, action) => ({ ...state, ...action.payload }),
		resetUser: () => defaultValueUser,
	},
});

export const { createUser, editUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
