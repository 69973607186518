import { createSlice } from '@reduxjs/toolkit';

export const rfcCSFSlice = createSlice({
	name: 'rfcCSF',
	initialState: '',
	reducers: {
		setRfcCSF: (state, action) => action.payload,
	},
});

export const { setRfcCSF } = rfcCSFSlice.actions;

export default rfcCSFSlice.reducer;
