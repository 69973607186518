import CheckIcon from '@mui/icons-material/Check';
import style from './styles/input-checkbox-styles.component.module.css';

interface InputCheckboxProps {
	label: string;
	value: boolean;
	onChange: () => void;
}
export default function InputCheckbox(props: InputCheckboxProps) {
	const { label, value, onChange } = props;

	return (
		<>
			<input
				type="checkbox"
				onChange={onChange}
				checked={value}
				style={{ display: 'none' }}
			/>
			<button
				onClick={onChange}
				className={style.content}
				role="checkbox"
				aria-checked={value}
			>
				{value ? (
					<div className={style.checked}>
						<CheckIcon style={{ fontSize: '16px', pointerEvents: 'none' }} />
					</div>
				) : (
					<div className={style.unChecked}></div>
				)}
				{label}
			</button>
		</>
	);
}
