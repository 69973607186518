import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button.component';
import styles from './styles/styles.component.module.css';
import CustomAlertCC from '../custom-alert/custom-alert-cc.component';
import { CreateAccountContext } from '../../context/CreateAccountContext';

const Step3 = () => {
	const { createAccountData } = useContext(CreateAccountContext);
	const navigator = useNavigate();
	return (
		<>
			<CustomAlertCC message="Activación de cuenta" />
			<p className={styles.colorGray2}>
				En las próximas horas deberás recibir un correo a tu dirección registrada{' '}
				<strong>{createAccountData.correo}</strong> que contiene las instrucciones
				para activar tu cuenta. El remitente aparecerá como{' '}
				<strong>Portal Empresarial Infonavit</strong> y el asunto deberá de ser{' '}
				<strong>Activación de tu cuenta en el Portal Empresarial.</strong> <br />
				<br />
				Si no lo encuentas en la bandeja de entrada, búscalo en la bandeja de
				correos no deseados (SPAM).
			</p>
			<div className={styles.information}>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>Número de Registro Patronal</span>
					<span className={styles.contentRow}>{createAccountData.nrp}</span>
				</div>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>Correo electrónico</span>
					<span className={styles.contentRow}>{createAccountData.correo}</span>
				</div>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>Nombre</span>
					<span className={styles.contentRow}>
						{createAccountData.nombre +
							' ' +
							createAccountData.apPaterno +
							' ' +
							createAccountData.apMaterno}
					</span>
				</div>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>RFC</span>
					<span className={styles.contentRow}>{createAccountData.rfc}</span>
				</div>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>CURP</span>
					<span className={styles.contentRow}>{createAccountData.curp}</span>
				</div>
				<div className={styles.informationRow}>
					<span className={styles.nameRow}>Teléfono</span>
					<span className={styles.contentRow}>
						{createAccountData.telefono}
					</span>
				</div>
			</div>
			<CustomButton
				onClick={() => navigator('/')}
				variant="solid"
				styles={{ marginBottom: 20, width: 240, height: 48, alignSelf: 'center' }}
				label="Finalizar"
			/>
		</>
	);
};

export default Step3;
