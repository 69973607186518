export type User = {
	name: string;
	first_name: string;
	last_name: string;
	address: string;
};

export const defaultValueUser: User = {
	name: '',
	first_name: '',
	last_name: '',
	address: '',
};
