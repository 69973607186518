import PaperLogin from '../../components/paper-login.component';
import Confirmation from './components/confirmation';
import FormComponent from './components/form';
import { useRemovalOfRepresentative } from './hooks/useRemovalOfRepresentative';

import styles from './styles/style.module.css';
import Information from '../../components/information.component';
import { Footer } from '../../components';

const RemovalOfRepresentative = () => {
	const {
		page,
		pdfB64,
		nrp,
		folio,
		eFirmaData,
		cadenaOriginal,
		setPage,
		setPdfB64,
		setNrp,
		setFolio,
		setEFirmaData,
		setCadenaOriginal,
	} = useRemovalOfRepresentative();
	return (
		<>
			<div className={styles.paperContainer}>
				<PaperLogin>
					<div className={styles.mainContainer}>
						<h4>Solicitud de baja de perfil de representante legal</h4>
						{page === 'form' ? (
							<FormComponent
								setActualPage={setPage}
								setPdf={setPdfB64}
								nrp={nrp}
								setNrp={setNrp}
								setFolio={setFolio}
								eFirmaData={eFirmaData}
								setEFirmaData={setEFirmaData}
								setCadenaOriginal={setCadenaOriginal}
							/>
						) : (
							<Confirmation
								setActualPage={setPage}
								pdf={pdfB64}
								setPdf={setPdfB64}
								folio={folio}
								setFolio={setFolio}
								cadenaOriginal={cadenaOriginal}
								setCadenaOriginal={setCadenaOriginal}
								nrp={nrp}
								setNrp={setNrp}
								eFirmaData={eFirmaData}
								setEFirmaData={setEFirmaData}
							/>
						)}
					</div>
				</PaperLogin>
			</div>
			<Information />
			<Footer />
		</>
	);
};

export default RemovalOfRepresentative;
