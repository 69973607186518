// Dependencies
import React from 'react';

//Components
import { ContainerCardsInformation } from '../styled-components/card-information.styled-components';
import CardInformation from '../components/card-information.component';
import IconCustom from '../components/icon-custom.component';

//Icons
import person from '../assets/svg/user-follow-fill.svg';
import book from '../assets/svg/icono-guia-cae.svg';
import phone from '../assets/svg/icono-telefono.svg';

const Information = () => {
	return (
		<ContainerCardsInformation>
			<CardInformation
				icon={<IconCustom styles={{ width: 24 }} svg={phone} />}
				title="¿Tienes problemas para regístrate?"
			>
				Comunícate a Infonatel al 5591715050 o al 800 008 3900, opción 2 de
				patrones, de lunes a viernes de 9:00 a 18:00 horas.
			</CardInformation>
			<CardInformation
				icon={<IconCustom styles={{ width: 24 }} svg={person} />}
				title="Consulta la guía de registro"
			>
				<>
					Para facilitar el cumplimiento de tus obligaciones como patrón, revisa
					la{' '}
					<a
						href={process.env.REACT_APP_URL_GUIA_REGISTRO}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#293990' }}
					>
						Guía de registro
					</a>{' '}
					en el Portal Empresarial.
				</>
			</CardInformation>
			<CardInformation
				icon={<IconCustom styles={{ width: 24 }} svg={book} />}
				title="Genera una solicitud en el CAE"
			>
				<>
					Ponemos a tu disposición la{' '}
					<a
						href={process.env.REACT_APP_URL_GUIA_CAE}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#293990' }}
					>
						Guía CAE
					</a>{' '}
					(Centro de Atención Empresarial) para ingresar solicitudes y podamos
					seguir atendiéndote.
				</>
			</CardInformation>
		</ContainerCardsInformation>
	);
};

export default Information;
