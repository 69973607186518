import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLayout } from '../store/modules/layout';

interface IUseLayout {
	title: string;
	breadcrumbs?: string[];
	background?: string;
	guide?: string;
	about?: string;
}

export function useLayout({ title, breadcrumbs, background, about, guide }: IUseLayout) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			setLayout({
				title: title,
				breadcrumbs: breadcrumbs,
				background: background,
				guide: guide,
				about: about,
			})
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
}
