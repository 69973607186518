/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Input } from '@mui/material';
import { phrases } from './utils/captcha.component.utils';
import { Replay } from '@mui/icons-material';
import styles from './styles/captcha-styles.component.module.css';
import CustomAlert from './alert.component';
interface ICaptcha {
	value: string;
	onChange?: (newValue: string, codeCaptcha: string) => void;
	getNew: boolean;
	error: boolean;
	setError: React.Dispatch<React.SetStateAction<boolean>>;
	intServices?: boolean;
}

const randomPhrase = () => phrases[Math.floor(Math.random() * phrases.length)];

const Captcha = ({ value, onChange, getNew, error, setError, intServices }: ICaptcha) => {
	const [random1, setRandom1] = useState(randomPhrase());
	const [random2, setRandom2] = useState(randomPhrase());
	const [message, setMessage] = useState('');
	useEffect(() => {
		reloadCaptcha(false);
	}, [getNew]);

	const reloadCaptcha = (buttonClick: boolean) => {
		setRandom1(randomPhrase());
		setRandom2(randomPhrase());
		if (buttonClick) setError(false);
		if (onChange) onChange('', `${random1} ${random2}`);
	};

	useEffect(() => {
		if (intServices) {
			value === ''
				? setMessage('Ingrese los caracteres de la imagen.')
				: setMessage('Los caracteres son incorrectos.');
		} else {
			setMessage('Error en el Captcha.');
		}
	}, [value]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.phraseContainer}>
					<svg viewBox="0 0 200 50">
						<path
							fill="transparent"
							id="curve"
							d="M0,4 C77,50 117,30 197,10"
						/>
						<text x="20">
							<textPath xlinkHref="#curve" className={styles.textLine}>
								{random1} {random2}
							</textPath>
						</text>
					</svg>
				</div>
				<div className={styles.inputContainer}>
					<Input
						disableUnderline
						size="small"
						placeholder="Introduce el código"
						name="captchaInput"
						className={styles.inputCaptcha}
						value={value}
						inputProps={{
							'data-testid': 'captchaInput',
						}}
						onChange={(e) => {
							const value = e.target.value;
							if (value.length > 20) return;
							if (onChange) {
								onChange(value, `${random1} ${random2}`);
								value ? setError(false) : setError(true);
							} else return null;
						}}
					/>
					<div
						className={styles.reloadButton}
						data-testid="btnReload"
						onClick={() => {
							reloadCaptcha(true);
						}}
					>
						<Replay />
					</div>
				</div>
			</div>

			{error && <CustomAlert show={true} severity="error" message={message} />}
		</>
	);
};

export default Captcha;
