import { InputHTMLAttributes, useState } from 'react';
import { Label } from './label.component';
import { AlertInput } from './alert-input.component';
import { ShowAndHidePassword } from './show-and-hide-password.component';

import style from './styles/input-styles.component.module.css';

interface ITextareaProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: { valid: boolean; msg: string };
	label?: string;
	name: string;
	notCopy?: boolean;
	notPaste?: boolean;
}

export default function Input({
	error,
	label,
	name,
	notCopy,
	notPaste,
	...rest
}: ITextareaProps) {
	const [showPassword, setShowPassword] = useState(false);
	const clickShowPassword = () => setShowPassword(!showPassword);
	const styleInput = rest.value === '' ? style.empty : style.notEmpty;
	const type =
		rest.type === 'password' ? (showPassword ? 'text' : 'password') : rest.type;

	const onCopy = (e: any) => {
		if (notCopy) {
			e.preventDefault();
			return false;
		}
	};
	const onPaste = (e: any) => {
		if (notPaste) {
			e.preventDefault();
			return false;
		}
	};

	return (
		<div className={style.content}>
			<Label label={label} name={name} required={rest.required} />
			<div className={style.inputContent}>
				<input
					{...rest}
					className={`${style.input} ${styleInput}`}
					type={type}
					name={name}
					onCopy={onCopy}
					onPaste={onPaste}
				/>
				{rest.type === 'password' && (
					<ShowAndHidePassword
						show={showPassword}
						onClick={clickShowPassword}
					/>
				)}
			</div>
			{error && <AlertInput name={name} valid={error.valid} message={error.msg} />}
		</div>
	);
}
