// Components
import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Media
import logoLogin from '../assets/svg/logo-login.svg';

//Styles
import styles from './styles/paper-login-styles.component.module.css';

interface IPaperInformation {
	children: string | React.ReactElement;
}

const PaperLogin = (props: IPaperInformation) => {
	const { children } = props;
	const navigate = useNavigate();
	return (
		<Grid className={styles.mainContainer} item>
			<Grid className={styles.containerImgs}>
				<img
					onClick={() => navigate('/')}
					src={logoLogin}
					className={styles.imgLogoInfonavit}
					alt="logo-infonavit"
				/>
			</Grid>
			<Grid className={styles.mainContainerForm}>
				<Paper className={styles.containerForm}>{children}</Paper>
			</Grid>
		</Grid>
	);
};

export default PaperLogin;
