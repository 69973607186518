/* eslint-disable react-hooks/exhaustive-deps */
// Resources
import styles from '../style.module.css';

// Components
import CustomButton from '../../../components/button.component';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/modal.component';

interface ISuccessfullyRegister {
	correo: string;
}

export function SuccessfullyRegister({ correo }: ISuccessfullyRegister) {
	const navigator = useNavigate();
	return (
		<CustomModal openModal={true} type="successful">
			<div className={styles.successRegister}>
				<p
					className="Text-default Color-2-grey"
					style={{ margin: 0, textAlign: 'center' }}
				>
					En las próximas horas recibirás un mensaje de confirmación al correo
					electrónico:{' '}
					<span className="Text-Negrita" style={{ wordBreak: 'break-word' }}>
						{correo}
					</span>{' '}
					con las instrucciones para activar tu cuenta. Podrás localizarlo en tu
					bandeja de entrada o en correo no deseado. <br />
					<br /> El remitente será
					<span className="Text-Negrita"> Portal Empresarial Infonavit</span> y
					el asunto{' '}
					<span className="Text-Negrita">
						{' '}
						Activación de tu cuenta en el Portal Empresarial Infonavit.
					</span>
				</p>
				<div className={styles.containerButton}>
					<CustomButton
						onClick={() => navigator('/')}
						variant="solid"
						label="Finalizar"
					/>
				</div>
			</div>
		</CustomModal>
	);
}
export default SuccessfullyRegister;
