import { createSlice } from '@reduxjs/toolkit';

export const loadingRequestSlice = createSlice({
	name: 'loadingReq',
	initialState: { loadingRequest: false },
	reducers: {
		setLoadingRequest: (state, action) => ({ ...state, ...action.payload }),
		stopLoadingRequest: () => ({ loadingRequest: false }),
	},
});

export const { setLoadingRequest, stopLoadingRequest } = loadingRequestSlice.actions;

export default loadingRequestSlice.reducer;
