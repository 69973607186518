import { Grid } from '@mui/material';

//Interfaces
import { ITab } from '../interfaces/tab.interface';

//Styles
import style from './styles/tab-styles.component.module.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Tabs = ({
	children,
	containerColumns,
	containerPosition,
	tabs,
	active,
	onChangeTab,
	cardsType,
	styles,
}: ITab) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div className={style.container}>
			<Grid
				className={style.containerTabs}
				container
				columns={containerColumns || 12}
				gridColumn={containerColumns || 12}
				spacing={3}
			>
				{tabs.map((tab, indexTab) => {
					return (
						<Grid
							className={`${style.tabHeader} ${
								style[`tabOrder${matches ? tab.orderSM : tab.orderMD}`]
							}`}
							key={indexTab}
							item
							xs={containerColumns || 12}
							sm={containerColumns || 12}
							md={tab.columns}
							lg={tab.columns}
							xl={tab.columns}
						>
							<div className={style.containerSubItemTab}>
								<button
									disabled={tab.disabled}
									name={tab.title}
									onClick={() =>
										onChangeTab && onChangeTab(indexTab, tab)
									}
									className={`${
										active === indexTab
											? style.buttonTab
											: tab.checked
											? style.buttonTabChecked
											: style.buttonTabDisabled
									}`}
								>
									{tab.title}
									{tab.checked && (
										<img
											src={'test.png'}
											alt="check"
											style={{
												marginLeft: 10,
												width: 20,
											}}
										/>
									)}
								</button>
							</div>
						</Grid>
					);
				})}
				<Grid
					style={{
						paddingTop: '0',
						order: matches ? containerPosition : 7,
					}}
					item
					xs={containerColumns || 12}
					sm={containerColumns || 12}
					md={containerColumns || 12}
					lg={containerColumns || 12}
					xl={containerColumns || 12}
				>
					<div className={style.body} style={styles}>
						{children}
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Tabs;
