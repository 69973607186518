import { createSlice } from '@reduxjs/toolkit';

export const diezPlusSlice = createSlice({
	name: 'diezPlus',
	initialState: false,
	reducers: {
		setDiezPlusShow: (state, action) => action.payload,
	},
});

export const { setDiezPlusShow } = diezPlusSlice.actions;

export default diezPlusSlice.reducer;
