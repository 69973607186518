import { createSlice } from '@reduxjs/toolkit';

export const idSistemaSlice = createSlice({
	name: 'idSistemaReq',
	initialState: { idSistemaArr: [] },
	reducers: {
		setIdSistema: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { setIdSistema } = idSistemaSlice.actions;

export default idSistemaSlice.reducer;
