import { createSlice } from '@reduxjs/toolkit';

export const tabsSISlice = createSlice({
	name: 'tabsSI',
	initialState: 0,
	reducers: {
		setTab: (_state, action) => action.payload,
	},
});

export const { setTab } = tabsSISlice.actions;

export default tabsSISlice.reducer;
