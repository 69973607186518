export interface IDatosEfirma {
	psw: string;
	keyBinary: string | ArrayBuffer | null | undefined;
	cerBinary: string | ArrayBuffer | null | undefined;
	cerB64: string | undefined;
}

export const defaultEfirmaData = {
	keyBinary: '',
	cerBinary: '',
	cerB64: '',
	psw: '',
};

export interface IResponseEfirmaValidation {
	valido: boolean;
	rfc: string;
	nombre: string;
	curp: string;
	fechaVigenciaFin: string;
	fechaVigenciaInicio: string;
	mensajeValidacion: string;
}

export const defaulEfirmaValidationResponse = {
	valido: false,
	rfc: '',
	nombre: '',
	curp: '',
	fechaVigenciaFin: '',
	fechaVigenciaInicio: '',
	mensajeValidacion: '',
};

export interface IResponseEfirmaSigning {
	code: string;
	message: string;
	pdfSignedB64: string;
}

export const defaultEfirmaSigningResponse = {
	code: '',
	message: '',
	pdfSignedB64: '',
};
