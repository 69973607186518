/* eslint-disable react-hooks/exhaustive-deps */
// Resources
import styles from '../style.module.css';
import gifLoading from '../../../assets/gifts/loading.gif';

// Components
import { Grid } from '@mui/material';
import CustomButton from '../../../components/button.component';
import Captcha from '../../../components/captcha.component';
import TextInput from '../../../components/text-input.component';
import PaperLogin from '../../../components/paper-login.component';
import { useRecoverPassword } from '../hooks/useRecoverPassword';
import { INTERNAL_CODES, RESPONSE_CODES } from '../../../config/response-codes';
import CustomModal from '../../../components/modal.component';
import SuccessfullyRecoveredPassword from './succesfully-recovered-password';
import { useNavigate } from 'react-router-dom';

export function FormPassword() {
	const {
		handleSetData,
		handleValidate,
		setCodeInput,
		setCode,
		handleValidteFormFields,
		setOpenModal,
		setErrorCaptcha,
		data,
		codeInput,
		refreshCaptcha,
		errorCorreo,
		errorConfirmaCorreo,
		alert,
		valueLogin,
		status,
		validForm,
		errorCaptcha,
		openModal,
		setOpenLoading,
		openLoading,
	} = useRecoverPassword();
	const navigator = useNavigate();
	return (
		<>
			<Grid className={styles.subContainer} container spacing={0}>
				<CustomModal
					openModal={openLoading}
					setOpenModal={setOpenLoading}
					closeButton={false}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 38,
						}}
					>
						<img
							width={50}
							height={50}
							src={gifLoading}
							alt="loading-infonavit-gift"
						/>
						<p className={styles.textLoading}>Cargando...</p>
					</div>
				</CustomModal>
				<PaperLogin>
					<>
						<h4
							className="Text-Negrita"
							style={{ margin: 0, textAlign: 'center' }}
						>
							Olvidé mi contraseña
						</h4>
						<span className={styles.infoLabel}>
							Para restablecer tu contraseña proporciona la siguiente
							información
						</span>
						<TextInput
							id="correo"
							name="correo"
							placeholder="correo@electronico.com"
							labelStyles={{ fontSize: 16 }}
							value={data.correo}
							onChange={handleSetData}
							onBlur={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Correo electrónico
									<span className="mandatory">*</span>
								</p>
							}
							error={!errorCorreo.valid}
							helperText={!errorCorreo.valid ? errorCorreo.msg : ''}
							noCopy
							noPaste
						/>
						<TextInput
							id="confirmaCorreo"
							name="confirmaCorreo"
							placeholder="correo@electronico.com"
							labelStyles={{ fontSize: 16 }}
							value={data.confirmaCorreo}
							onChange={handleSetData}
							onBlur={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Confirma correo electrónico
									<span className="mandatory">*</span>
								</p>
							}
							error={!errorConfirmaCorreo.valid}
							helperText={
								!errorConfirmaCorreo.valid ? errorConfirmaCorreo.msg : ''
							}
							noCopy
							noPaste
						/>
						<div className={styles.label}>
							<p className="Text-Small" style={{ margin: '8px 0px' }}>
								<span className="mandatory">*</span>
								Datos obligatorios
							</p>
						</div>
						<div className={styles.containerCaptcha}>
							<Captcha
								value={codeInput}
								getNew={refreshCaptcha}
								onChange={(newValue: string, codeCaptcha: string) => {
									handleValidteFormFields(
										data.correo,
										data.confirmaCorreo,
										newValue.length > 0 ? true : false
									);
									setCodeInput(newValue);
									setCode(codeCaptcha);
								}}
								error={errorCaptcha}
								setError={setErrorCaptcha}
							/>
						</div>
						<div className={styles.containerButtonsForm}>
							<CustomButton
								onClick={() => navigator('/')}
								styles={{ width: '100%' }}
								variant="outlined"
								label="Regresar"
							/>
							<div className={styles.dividerButtons} />
							<CustomButton
								onClick={handleValidate}
								variant="solid"
								styles={{ width: '100%' }}
								label="Enviar"
								disabled={!validForm}
							/>
						</div>
					</>
				</PaperLogin>
				{(status === 'success' &&
					valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL) ||
				(status === 'error' &&
					valueLogin?.internalCode !== INTERNAL_CODES.SUCCESSFUL) ? (
					<CustomModal
						openModal={openModal}
						setOpenModal={setOpenModal}
						title={alert.title}
						type={alert.type}
						labelButton="Cerrar"
						closeButton={true}
						style={{ fontSize: 18, fontWeight: 400 }}
					>
						<>{alert.body}</>
					</CustomModal>
				) : status === 'success' &&
				  valueLogin?.code === RESPONSE_CODES.SUCCESSFUL ? (
					<SuccessfullyRecoveredPassword correo={data.correo} />
				) : null}
			</Grid>
		</>
	);
}
export default FormPassword;
