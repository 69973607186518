/* eslint-disable react-hooks/exhaustive-deps */
import Footer from '../../components/footer.component';
import Information from '../../components/information.component';
import FormRecoverPassword from './components/form-recover-password';

const RecoverPassword = () => {
	return (
		<>
			<FormRecoverPassword />
			<Information />
			<Footer />
		</>
	);
};
export default RecoverPassword;
