export const EMPTY_FIELDS = {
	NRP: 'Escribe el Número de Registro Patronal (NRP) de la empresa.',
	EMAIL: 'Escribe tu correco electrónico.',
	EBA: 'Escribe el valor de la emisión bimestral anticipada (EBA) que te pide el Portal.',
	NOMBRE: 'Escribe tus nombres.',
	APELLIDO_PATERNO: 'Escribe tu primer apellido.',
	RFC: 'Escribe tu Registro Federal de Contribuyentes (RFC).',
	RFC_NOT_VALID:
		'El Registro Federal de Contribuyentes (RFC) debe tener 13 caracteres alfanuméricos.',
	CURP: 'Escribe tu Clave Única de Registro de Población (CURP). Intenta de nuevo.',
	CURP_NOT_VALID:
		'La Clave Única de Registro de Población (CURP) debe tener 18 caracteres alfanuméricos.',
	TELEFONO: 'Escribe tu Lada y Teléfono.',
	TELEFONO_NOT_VALID:
		'Escribe los 10 dígitos de tu número telefónico incluyendo la clave Lada, sin guiones ni espacios. Intenta de nuevo.',
	CONFIRMACION_CONTRASENIA: 'Confirma tu contraseña.',
};
