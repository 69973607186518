// Dependencies
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Resources
import { setLoading } from '../../../store/modules/loading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import {
	IActiveAccountParams,
	IActiveAccountResponse,
} from '../interfaces/auth.interface';
import { RESPONSE_CODES } from '../../../config/response-codes';

export function useActiveAccount() {
	const queryParams = new URLSearchParams(window.location.search);
	const mail = queryParams.get('correo')?.toString() as string;
	const data: IActiveAccountParams = {
		correo: mail,
	};
	const dispatch = useDispatch();

	const {
		execute,
		value: valueLogin,
		status,
	} = useApiRequest<IActiveAccountParams, IActiveAccountResponse>({
		path: 'autentica/activateUser',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		execute({ ...data });
		handleValidateLogin();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		handleValidateLogin();
	}, [valueLogin?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleValidateLogin = () => {
		if (status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
			dispatch(setLoading({ loading: false }));
		} else if (
			status === 'success' &&
			valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			dispatch(setLoading({ loading: false }));
		}
	};

	return {
		valueLogin,
		status,
		RESPONSE_CODES,
	};
}
