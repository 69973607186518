import { Visibility, VisibilityOff } from '@mui/icons-material';
import style from './styles/show-and-hide-password-styles.component.module.css';

interface ShowPasswordProps {
	show: boolean;
	onClick: () => void;
}

export function ShowAndHidePassword({ show, onClick }: ShowPasswordProps) {
	return (
		<button className={style.button} onClick={onClick} tabIndex={-1} type="button">
			{show ? <Visibility /> : <VisibilityOff />}
		</button>
	);
}
