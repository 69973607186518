import { useState, useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/system';
import { Pagination } from './pagination.component';
import style from './styles/table-styles.component.module.css';

interface IDataTableProps {
	headers: { id: string; name: string }[];
	data: any[];
	align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	pagination?: boolean;
	rowsPerPage?: number;
	labelStyles?: React.CSSProperties;
	messageEmpty?: string;
}

export default function DataTable({
	headers,
	data,
	rowsPerPage = 10,
	pagination,
	labelStyles,
	messageEmpty,
	align,
}: IDataTableProps) {
	const [page, setPage] = useState<number>(1);
	const [scroll, setScroll] = useState<boolean>(false);
	const count = Math.ceil(data.length / rowsPerPage);
	const startDataPerPage = (page - 1) * rowsPerPage;
	const endDataPerPage = (page - 1) * rowsPerPage + rowsPerPage;

	const ParNumber = (number: number) => number % 2 === 0;

	const useHorizontalScrollEvent = (callback: any) => {
		const positionRef = useRef(0);
		return (e: any) => {
			const x = e.currentTarget.scrollLeft;
			if (x !== positionRef.current) {
				positionRef.current = x;
				callback(positionRef.current);
			}
		};
	};

	const handleScroll = useHorizontalScrollEvent((e: any) => {
		e === 0 ? setScroll(false) : setScroll(true);
	});

	useEffect(() => {
		setPage(1);
	}, [data]);

	return (
		<>
			<TableContainer
				id={style.tabla}
				component={Paper}
				sx={{ ...labelStyles }}
				onScroll={handleScroll}
			>
				<Table>
					<TableHead className={style.header} style={{ textAlign: align }}>
						<tr>
							{headers.map((header, index) => (
								<th
									className={`${
										index === 0 ? style.stickyHeader : ''
									} ${style.headerTitle} ${
										index === 0 && scroll ? style.scroll : ''
									}`}
									key={header.id}
								>
									{header.name}
								</th>
							))}
						</tr>
					</TableHead>
					<TableBody>
						{data.length === 0 ? (
							<TableRow>
								<td colSpan={headers.length} className={style.empty}>
									{messageEmpty ? messageEmpty : 'Sin resultados'}
								</td>
							</TableRow>
						) : (
							data
								.slice(startDataPerPage, endDataPerPage)
								.map((emp, index) => (
									<TableRow key={index}>
										{headers.map((header, index2) => (
											<td
												style={{
													...labelStyles,
													textAlign: align,
												}}
												key={header.id}
												className={`${
													ParNumber(index)
														? style.par
														: style.impar
												} ${index2 === 0 ? style.sticky : ''}
											${index2 === 0 && scroll ? style.scroll : ''}
											`}
											>
												{emp[header.id]}
											</td>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				display={pagination ? 'none' : 'flex'}
				justifyContent="center"
				flex={1}
				marginTop={'20px'}
			>
				{count > 1 && (
					<Stack spacing={2} className={style.stack}>
						<Pagination
							showFirstButton
							showLastButton
							count={count}
							onChange={(_e, page) => setPage(page)}
						/>
					</Stack>
				)}
			</Box>
		</>
	);
}
