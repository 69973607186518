import CustomButton from '../../../components/button.component';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/styles.module.css';
import failIcon from '../../../assets/svg/failModal.svg';
import { IconCustom } from '../../../components';

export const ErrorMessage = () => {
	const navigate = useNavigate();

	return (
		<>
			<span>
				<IconCustom styles={{ width: '57' }} svg={failIcon} />
			</span>
			<span className={styles.txtIntro}>
				Algo salió mal con la activación de esta cuenta.
				<br />
				Intenta de nuevo más tarde
			</span>
			<CustomButton
				onClick={() => navigate('/')}
				variant="outlined"
				label="Regresar a la página principal"
				styles={{ width: '50%', marginTop: '20px' }}
			/>
		</>
	);
};
