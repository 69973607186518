import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//routes for navigation
import { procedures } from '../router/procedures';
import { queries } from '../router/queries';
import { attentionCenter } from '../router/attention-center';

import style from './styles/navigation-styles.component.module.css';
import { getProfile } from '../utils/getProfile';

export default function Navigation() {
	const [showProcedure, setShowProcedure] = useState(false);
	const [showConsult, setShowConsult] = useState(false);
	const [show, setShow] = useState(false);
	//const [servicios, setServicios] = useState<any[]>([]);
	const [firstProcedure, setFirstProcedure] = useState<any[]>([]);
	const [secondProcedure, setSecondProcedure] = useState<any[]>([]);
	const [thirdProcedure, setThirdProcedure] = useState<any[]>([]);
	const [firstQueries, setFirstQueries] = useState<any[]>([]);
	const [firstAttention, setFirstAttention] = useState<any[]>([]);

	//version mobile
	const handleShowProcedure = () => setShowProcedure(!showProcedure);
	const handleShowConsult = () => setShowConsult(!showConsult);
	const handleShow = () => setShow(!show);
	useEffect(() => {
		const profile = getProfile();
		const servicePerfil = profile?.contacto.servicios || [];
		const isArray = servicePerfil instanceof Array;
		const servicePerfilArr = isArray ? servicePerfil : [servicePerfil];
		const procedureFirst: any[] = [];
		procedures.slice(0, 2).map((procedure) =>
			servicePerfilArr?.map((servicio) => {
				return (
					servicio?.idSistema === procedure.idSistema &&
					procedureFirst.push(procedure)
				);
			})
		);
		const procedureSecond: any[] = [];
		procedures.slice(2, 5).map((procedure) =>
			servicePerfilArr?.map((servicio) => {
				return (
					servicio?.idSistema === procedure.idSistema &&
					procedureSecond.push(procedure)
				);
			})
		);
		const procedureThird: any[] = [];
		procedures.slice(5, 11).map((procedure) =>
			servicePerfilArr?.map((servicio) => {
				return (
					servicio?.idSistema === procedure.idSistema &&
					procedureThird.push(procedure)
				);
			})
		);
		const queriesFirst: any[] = [];
		queries.map((query) =>
			servicePerfilArr?.map((servicio) => {
				return (
					servicio?.idSistema === query.idSistema && queriesFirst.push(query)
				);
			})
		);
		const attentionFirst: any[] = [];
		attentionCenter.map((attention) =>
			servicePerfilArr?.map((servicio) => {
				return (
					servicio?.idSistema === attention.idSistema &&
					attentionFirst.push(attention)
				);
			})
		);
		setFirstProcedure(procedureFirst);
		setSecondProcedure(procedureSecond);
		setThirdProcedure(procedureThird);
		setFirstQueries(queriesFirst);
		setFirstAttention(attentionFirst);
		//setServicios(servicePerfilArr);
	}, []);
	return (
		<>
			<div className={style.container}>
				{(firstProcedure.length > 0 ||
					secondProcedure.length > 0 ||
					thirdProcedure.length > 0) && (
					<div className={style.content}>
						<div className={style.item} onClick={handleShowProcedure}>
							<p>Mis trámites</p>
						</div>
						<div
							className={`${style.submenuTramites} ${
								showProcedure ? style.submenuActive : ''
							}`}
						>
							<div className={style.submenuContent}>
								{firstProcedure.length > 0 && (
									<>
										<div className={style.subTitle}>
											<p>Realiza tus aportaciones</p>
										</div>
										{firstProcedure.map(({ path, name }) => (
											<Link
												key={path}
												to={path}
												className={style.subitem}
												onClick={handleShowProcedure}
											>
												{name}
											</Link>
										))}
									</>
								)}
								{secondProcedure.length > 0 && (
									<>
										<div className={style.subTitle}>
											<p>Aclaraciones y comprobantes</p>
										</div>
										{secondProcedure.map(({ path, name }) => (
											<Link
												key={path}
												to={path}
												className={style.subitem}
												onClick={handleShowProcedure}
											>
												{name}
											</Link>
										))}
									</>
								)}
							</div>
							<div className={style.submenuContent}>
								{thirdProcedure.length > 0 && (
									<>
										<div className={style.subTitle}>
											<p>Trámites adicionales</p>
										</div>
										{thirdProcedure.map(({ path, name }) => (
											<Link
												key={path}
												to={path}
												className={style.subitem}
												onClick={handleShowProcedure}
											>
												{name}
											</Link>
										))}
									</>
								)}
							</div>
						</div>
					</div>
				)}
				{firstQueries.length > 0 && (
					<div className={style.content}>
						<div className={style.item} onClick={handleShowConsult}>
							<p>Consultas</p>
						</div>
						<div
							className={`${style.submenu} ${
								showConsult ? style.submenuActive : ''
							}`}
						>
							{firstQueries.map(({ path, name }) => (
								<Link
									key={path}
									to={path}
									className={style.subitem}
									onClick={handleShowConsult}
								>
									{name}
								</Link>
							))}
						</div>
					</div>
				)}
				{firstAttention.length > 0 && (
					<div className={style.content}>
						<div className={style.item} onClick={handleShow}>
							<p>Centro Atención Empresarial</p>
						</div>
						<div
							className={`${style.submenu} ${
								show ? style.submenuActive : ''
							}`}
						>
							{firstAttention.map(({ path, name }) => (
								<Link
									key={path}
									to={path}
									className={style.subitem}
									onClick={handleShow}
								>
									{name}
								</Link>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
}
