import medios from '../../../assets/svg/ico-medios-pago.svg';
import aclaraciones from '../../../assets/svg/ico-devolucion-pagos.svg';
import consulta from '../../../assets/svg/ico-eba.svg';
import servicios from '../../../assets/svg/ico-comunicacion.svg';
import sisub from '../../../assets/svg/ico-sisub.svg';
import tramite from '../../../assets/svg/ico-convenio-tradicional.svg';
import style from '../styles/home-styles.component.module.css';
import { Link } from 'react-router-dom';
import { getProfile } from '../../../utils/getProfile';

const ThirdComponent = () => {
	const profile = getProfile();
	const servicePerfil = profile?.contacto.servicios || [];
	const isArray = servicePerfil instanceof Array;

	const servicePerfilArr = isArray ? servicePerfil : [servicePerfil];
	const servicesList = [
		{
			first: 'Medios',
			second: 'de pago',
			img: medios,
			url: '/mis-tramites/medios-de-pago',
			idSistema: '12',
		},
		{
			first: 'Aclaraciones',
			second: 'patronales',
			img: aclaraciones,
			url: '/mis-tramites/aclaraciones-patronales',
			idSistema: '11',
		},
		{
			first: 'Servicios de',
			second: 'intercomunicación',
			img: servicios,
			url: '/mis-tramites/servicios-de-intercomunicacion',
			idSistema: '19',
		},
		{
			first: 'Trámite de',
			second: 'crédito',
			img: tramite,
			url: '/mis-tramites/tramite-de-credito',
			idSistema: '23',
		},
		{
			first: 'Consulta de',
			second: 'trabajadores',
			img: consulta,
			url: '/consultas/trabajadores-acreditados',
			idSistema: '6',
		},
		{
			first: 'SISUB',
			second: '',
			img: sisub,
			url: '/mis-tramites/sistema-de-informacion-de-subcontratacion',
			idSistema: '22',
		},
	];
	const servicesUser: any[] = [];
	for (const service of servicesList) {
		servicePerfilArr.filter(
			(servicio: any) =>
				servicio?.idSistema === service.idSistema && servicesUser.push(service)
		);
	}

	return servicesUser.length > 0 ? (
		<div className={style.thirdComponent}>
			<b className={style.serviciosTitle}>Servicios más solicitados</b>
			<div className={style.containerServices}>
				{servicesUser?.map((service, i) => {
					return (
						<Link to={service.url} className={style.serviciosText} key={i}>
							<img
								src={service.img}
								className={style.imgServicios}
								alt="img"
							/>
							{service.first}
							<br />
							{service.second}
						</Link>
					);
				})}
			</div>
		</div>
	) : (
		<div />
	);
};

export default ThirdComponent;
