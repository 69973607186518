import { useState } from 'react';

//interfaces and constants
import { IModal, IOpenModal } from '../interfaces/profile.interface';
import { defaultModal } from '../constants/profile.constants';
import { INTERNAL_MESSAGES } from '../../../config/response-codes';

export function useModal() {
	const [modal, setModal] = useState<IModal>(defaultModal);
	const closeModal = () =>
		setModal((prevModal) => ({ ...prevModal, show: false, openModal: false }));

	const openModal = ({ type, button, message }: IOpenModal) => {
		setModal({
			show: true,
			openModal: true,
			message: message ? message : INTERNAL_MESSAGES.ERROR_MESSAGE,
			type: type,
			button: button,
			closeModal: closeModal,
		});
	};

	return { modal, openModal };
}
