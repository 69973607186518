import { lazy, LazyExoticComponent } from 'react';

//routes
import HomePage from '../pages/home-page';

//Lazy routes
const ProfilePage = lazy(() => import('../pages/profile/profile'));
const UserManagementPage = lazy(() => import('../pages/user-management'));

type JSXComponent = () => JSX.Element;

interface Route {
	path: string;
	to: string;
	isPrivate?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
}

export const routes: Route[] = [
	{
		to: '/inicio',
		path: '/inicio',
		name: 'Inicio',
		Component: HomePage,
		isPrivate: true,
	},
	{
		to: '/perfil',
		path: '/perfil',
		name: 'Perfil',
		Component: ProfilePage,
		isPrivate: true,
	},
	{
		to: '/administracion-usuarios',
		path: '/administracion-usuarios',
		name: 'Administración de usuarios',
		Component: UserManagementPage,
		isPrivate: true,
	},
];
