/* eslint-disable react-hooks/exhaustive-deps */
// Components
import { useActiveAccount } from './hooks/useActiveAccount';
import { useNavigate } from 'react-router-dom';

const ActivateAccount = () => {
	const { valueLogin, status, RESPONSE_CODES } = useActiveAccount();
	const navigator = useNavigate();
	return (
		<>
			{status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL
				? navigator('/?register=true')
				: navigator('/?register=false')}
		</>
	);
};
export default ActivateAccount;
