import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useOnClickOutside } from './useOnClickOutside';

interface IValue {
	label: string;
}

interface IUseAutocomplete {
	validate?: (value: string) => boolean;
	afterSelected: (value: {} | null) => void;
	options: { label: string }[];
	searchValue?: string;
	value: IValue | null;
	onChange: (e: any) => void;
}

export function useAutocomplete({
	afterSelected,
	options,
	validate,
	searchValue,
	value,
	onChange,
}: IUseAutocomplete) {
	const [inputValue, setInputValue] = useState('');
	const [groupedOptions, setGroupedOptions] = useState<{ label: string }[]>([]);
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		value ? setInputValue(value.label) : setInputValue('');
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if ((validate && validate(e.target.value)) || !validate) {
			setInputValue(e.target.value);
			const filteredOptions = options.filter((option) =>
				option.label.toLowerCase().includes(e.target.value.toLowerCase())
			);
			setGroupedOptions(filteredOptions);
			const filteredOptions2 = options.filter(
				(option) => option.label === e.target.value
			);
			if (filteredOptions2.length > 0) {
				onChange(filteredOptions2[0]);
				setInputValue(filteredOptions2[0].label);
				afterSelected(filteredOptions2[0]);
				return filteredOptions2[0];
			}
		}
	};

	const handleClick = (option: { label: string }) => () => {
		afterSelected(option);
		onChange(option);
		setInputValue(option.label);
		setIsFocused(false);
	};

	const handleBlur = () => {
		if (inputValue === '') {
			onChange(null);
		} else {
			value ? setInputValue(value.label) : setInputValue('');
		}
	};

	const handleClickOutside = () => setIsFocused(false);

	const handleFocus = () => setIsFocused(true);

	useOnClickOutside(ref, handleClickOutside);

	return {
		getInputProps: () => ({
			value: inputValue,
			onBlur: handleBlur,
			onFocus: handleFocus,
			onChange: handleChange,
		}),
		handleClick,
		validate,
		groupedOptions,
		isFocused,
		ref,
		searchValue,
	};
}
