import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ModuloEfirma, TextInput } from '../../../../components';
import CustomButton from '../../../../components/button.component';
import CustomModal from '../../../../components/modal.component';
import { useForm } from './hooks/useForm';

import styles from './styles/styles.component.module.css';
import LoadingIcon from '../../../../assets/gifts/loading.gif';
import { IDatosEfirma } from '../../../../interfaces/efirma.interface';

export interface IFormComponent {
	nrp: string;
	setNrp: React.Dispatch<React.SetStateAction<string>>;
	setFolio: React.Dispatch<React.SetStateAction<string>>;
	setPdf: React.Dispatch<React.SetStateAction<string>>;
	setCadenaOriginal: React.Dispatch<React.SetStateAction<string>>;
	setActualPage: React.Dispatch<React.SetStateAction<'form' | 'confirmation'>>;
	eFirmaData: IDatosEfirma;
	setEFirmaData: React.Dispatch<React.SetStateAction<IDatosEfirma>>;
}

const FormComponent = (props: IFormComponent) => {
	const navigate = useNavigate();
	const {
		disabledButton,
		eFirmaData,
		errorPassEfirma,
		errorKeyFile,
		errorCerFile,
		nrp,
		nrpError,
		showModal,
		modalMessage,
		showConfirmationModal,
		resetData,
		isLoading,
		setEFirmaData,
		handleNrpChange,
		setShowModal,
		validateFiel,
		setShowConfirmationModal,
		handleCancelConfirmation,
		handleAcceptConfirmation,
	} = useForm(props);
	return (
		<>
			{!isLoading ? (
				<>
					<CustomModal
						openModal={showModal}
						labelButton="Cerrar"
						closeButton={true}
						setOpenModal={setShowModal}
						type="error"
					>
						<>{modalMessage}</>
					</CustomModal>
					<CustomModal
						title="Baja de perfil"
						openModal={showConfirmationModal}
						labelButton="Cerrar"
						type="normal"
						setOpenModal={setShowConfirmationModal}
						closeIconCallback={handleCancelConfirmation}
					>
						<div className={styles.modalInfoContainer}>
							<span>¿Estás seguro que quieres borrar el registro?</span>
							<div className={styles.buttonsContainer}>
								<CustomButton
									onClick={handleCancelConfirmation}
									styles={{ width: '211px' }}
									variant="outlined"
									label="Cancelar"
								/>
								<CustomButton
									onClick={handleAcceptConfirmation}
									styles={{ width: '211px' }}
									variant="solid"
									label="Aceptar"
								/>
							</div>
						</div>
					</CustomModal>
					<div className={styles.nrpContainer}>
						<span className={styles.txtInfo}>
							Ingresa la informacion solicitada. Si tienes dudas, puedes
							descargar la{' '}
							<a href={process.env.REACT_APP_URL_LEGAL_REP_GUIDE}>
								Guía de usuario
							</a>{' '}
							correspondiente.
						</span>
						<TextInput
							id="nrp"
							name="nrp"
							placeholder="11 dígitos"
							labelStyles={{ fontSize: 16 }}
							value={nrp}
							onChange={handleNrpChange}
							onBlur={handleNrpChange}
							labelComponent={
								<span className={styles.labelContainerInput}>
									Ingresa tu NRP<span className="mandatory">*</span>
								</span>
							}
							helperText={nrpError.valid ? '' : nrpError.msg}
							maxLength={11}
						/>
					</div>
					<span className={styles.txtInfo}>
						Adjunta los archivos de la e.firma (empresa).
					</span>
					<div className={styles.efirmaContainer}>
						<ModuloEfirma
							efirmaData={eFirmaData}
							setEfirmaData={setEFirmaData}
							errorPassEfirma={errorPassEfirma}
							errorKeyFile={errorKeyFile}
							errorCerFile={errorCerFile}
							styles={{
								display: 'flex',
								flexDirection: 'column',
								gap: '40px',
							}}
							resetData={resetData}
						/>
						<span>
							<span className="mandatory">*</span>
							Datos obligatorios
						</span>
						<div className={styles.buttonsContainer}>
							<CustomButton
								onClick={() => navigate('/')}
								variant="outlined"
								label="Regresar"
							/>
							<CustomButton
								onClick={validateFiel}
								variant="solid"
								label="Validar"
								disabled={disabledButton}
							/>
						</div>
					</div>
				</>
			) : (
				<>
					<img src={LoadingIcon} alt="LoadingIcon" width={50} height={50} />
					<span className={styles.bajaTxt}>
						Baja del perfil en proceso.
					</span>{' '}
				</>
			)}
		</>
	);
};

export default FormComponent;
